import React from 'react';
import Swal from 'sweetalert';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import { updateUserById, getProductsById } from '../../services';
import { trackPageView, trackEvent } from '../../helpers/analytics';

const cartHelper = require('../../helpers/appHelper');

class Wishlist extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      loading: false,
      wishlist: user && user.wishlist !== undefined ? user.wishlist : [],
    };
  }

  componentDidMount() {
    const { distributor } = this.props;
    this.getProductsList(distributor);
    trackPageView();
  }

  getProductsList = async (distributor) => {
    const { wishlist } = this.state;
    const { dispatch, history } = this.props;
    this.setState({ loading: true }, async () => {
      try {
        const response = await getProductsById(wishlist);
        if (response) {
          if (response.status === 200) {
            const productList = [];
            map(response.data, (product) => {
              map(Object.keys(distributor.business_unit), (item) => {
                let discountedPrice = 0;
                if (item === product.business_unit) {
                  const discountPercent = distributor.business_unit[product.business_unit];
                  if (discountPercent > 0) {
                    const discountprice = product.price * discountPercent;
                    discountedPrice = product.price - discountprice;
                    product.discounted_price = discountedPrice;
                  }
                  product.discounted_price = discountedPrice;
                  product.asked_price = 0;
                  product.asked_discountpercent = 0;
                  product.asked_discountprice = 0;
                  product.reason = '';
                  if (product?.portfolio === "red") {
                    product.discounted_price = 0
                  }
                  productList.push(product);
                }
              });
            });
            this.setState({ products: productList || [], loading: false });
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
              await dispatch({ type: 'USER', payload: {} });
              await localStorage.removeItem('token');
              history.push('/');
            }
            this.setState({ loading: false });
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
          }
        }
      } catch (error) {
        console.warn(error);
        this.setState({ loading: false });
      }
    });
  };

  delete = (product) => {
    trackEvent('Wishlist', 'Delete', product.name);
    let { wishlist } = this.state;
    const { user, dispatch, history } = this.props;
    const { id } = user;
    Swal({
      title: product.name,
      text: `Are you sure that you want to remove this from your wishlist?`,
      icon: 'warning',
      dangerMode: true,
      buttons: true,
      modal: false,
    }).then(async (status) => {
      if (status) {
        if (wishlist.includes(product.id)) {
          wishlist = wishlist.filter((id) => id !== product.id);
          await this.setState({ wishlist });
          const updateUserData = {
            wishlist: wishlist,
          };
          const response = await updateUserById(id, updateUserData);
          try {
            if (response) {
              if (response.status === 200 || response.status === 201) {
                dispatch({
                  type: 'EDIT_LOGGED_USER',
                  payload: {
                    ...updateUserData,
                  },
                });
              } else {
                if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                  toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                  await dispatch({ type: 'USER', payload: {} });
                  await localStorage.removeItem('token');
                  history.push('/');
                }
                if (response.response.status >= 500) {
                  toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
                }
              }
            }
          } catch (error) {
            console.warn(error);
          }
        }
      }
    });
  };

  addToCart = (product) => {
    const { cart, dispatch, user } = this.props;
    const { id } = user;
    const updatedCart = cartHelper.addToCart(product, 1, cart, id);
    toast.success(`${product.name} has been added to the cart.`);
    trackEvent('Wishlist', 'Add to Cart', product.name);
    dispatch({ type: 'CART', payload: updatedCart });
  };

  render() {
    const { products, wishlist } = this.state;
    const finalProducts = (products || []).filter((product) => wishlist.includes(product.id));
    return (
      <>
        <Spinner display={this.state.loading}>
          {finalProducts.length > 0 ? (
            <div className="container-fluid mt-3">
              <div className="card" data-toggle="lists">
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>Material</th>
                        <th className="text-center">Shelf Life (in Days)</th>
                        <th className="text-center">List Price</th>
                        <th className="text-center">Discounted Price </th>
                        <th className="text-center">Lead Time (in Days)</th>
                        <th className="text-center">Quote Validity (in Days)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {finalProducts &&
                        finalProducts.map((product) => (
                          <tr key={product?.id}>
                            <td style={{ maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} data-toggle="tooltip" title={product.name}>
                              {product?.name}
                              <br />
                              <span className="small text-muted">{product?.id}</span>
                              <div>
                                <b>Business Unit: </b> <span className="small text-muted">{product?.business_unit}</span>
                              </div>
                            </td>
                            <td className="text-center">{product?.self_life ? `${product.self_life}` : '-'}</td>
                            <td className="text-center">
                              <Amount value={product.price} />
                            </td>
                            <td className="text-center">{product?.discounted_price > 0 ? <Amount value={product.discounted_price} /> : '-'}</td>
                            <td className="text-center">{product?.leadtime}</td>
                            <td className="text-center">60</td>
                            <td className="text-right">
                              <button className="btn btn-sm btn-success" onClick={() => this.addToCart(product)} data-toggle="tooltip" data-placement="top" title="Add to Cart">
                                <i className="fe fe-shopping-cart mr-1" />
                              </button>
                              <button className="btn btn-sm btn-rounded-circle btn-light ml-2" onClick={() => this.delete(product)} data-toggle="tooltip" data-placement="top" title="Delete">
                                <i className="fe fe-trash" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="m-5 text-center">
              <div className="row">
                <div className="col-lg-4 offset-lg-4 text-center">
                  <img src="/img/empty_wishlist.svg" className="img-fluid" alt="no-data-found" />
                  <p className="mt-3">
                    Your wishlist is empty! Add your frequently purchased <Link to="/products">products</Link> here for quick order.
                  </p>
                </div>
              </div>
            </div>
          )}
        </Spinner>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
    distributor: state.distributor,
  };
};

export default connect(mapStateToProps)(Wishlist);
