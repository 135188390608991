/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Header = ({ title, children }) => {
  return (
    <nav className="header">
      <Container>
        <div className="header-body">
          <Row className="align-items-center">
            <Col>
              <h1 className="header-title">{title}</h1>
            </Col>
            {children && <div className="col-auto text-right">{children}</div>}
          </Row>
        </div>
      </Container>
    </nav>
  );
};

export default Header;
