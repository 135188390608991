import React from 'react';
import 'firebase/auth';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import SimpleReactValidator from 'simple-react-validator';
import { registerUser } from '../../services/authServices';
import { TAX_PATTERN } from '../../admin/constants/Constants';
import { trackPageView, trackEvent } from '../../helpers/analytics';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      name: '',
      email: '',
      taxId: '',
      state: '',
      phone1: '',
      phone2: '',
      company: '',
      zipCode: '',
      address1: '',
      terms: false,
      designation: '',
      companyClass: 'is-invalid',
      taxIdClass: 'is-invalid',
      addressClass: 'is-invalid',
      cityClass: 'is-invalid',
      stateClass: 'is-invalid',
      codeClass: 'is-invalid',
      nameClass: 'is-invalid',
      emailClass: 'is-invalid',
      isSubmitDisabled: false,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    trackPageView();
  }

  onTermsAndConditionChange = (e) => {
    this.setState({
      terms: e.target.checked,
    });
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    switch (event.target.name) {
      case 'company':
        this.setState(
          {
            companyClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.companyClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      case 'address1':
        this.setState(
          {
            addressClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.addressClass === 'is-invalid') {
              this.validator.showMessages('address1');
              this.forceUpdate();
            }
          }
        );
        break;

      case 'city':
        this.setState(
          {
            cityClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.cityClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      case 'name':
        this.setState(
          {
            nameClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.nameClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;
      case 'state':
        this.setState(
          {
            stateClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.stateClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      case 'email':
        let emailPattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        this.setState(
          {
            emailClass: emailPattern.test(event.target.value) ? 'is-valid' : 'is-invalid',
          },
          () => {
            if (this.state.emailClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      default:
        break;
    }
  };

  onTinChange = (event) => {
    let taxIdPattern = TAX_PATTERN;
    this.setState(
      {
        taxId: event.target.value,
        taxIdClass: taxIdPattern.test(event.target.value) ? 'is-valid' : 'is-invalid',
      },
      () => {
        if (this.state.taxIdClass === 'is-invalid') {
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
    );
  };

  onCodeChange = ({ target: { name, value } }) => {
    if (!isNaN(Number(value))) {
      this.setState({ [name]: value });
      this.setState(
        {
          codeClass: value.length <= 0 ? 'is-invalid' : 'is-valid',
        },
        () => {
          if (this.state.codeClass === 'is-invalid') {
            this.validator.showMessages();
            this.forceUpdate();
          }
        }
      );
    }
  };

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    let selection = newState.selection;
    let cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.taxId.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  onSubmit = (event) => {
    const { history } = this.props;
    event.preventDefault();
    trackEvent('Register', 'Submit');
    this.setState({ loading: true }, async () => {
      let { company, taxId, address1, city, state, zipCode, phone1, phone2, name, designation, email } = this.state;
      document.getElementById('tAndC').innerHTML = '';
      if (this.state.companyClass === 'is-valid' && this.state.taxIdClass === 'is-valid' && this.state.addressClass === 'is-valid' && this.state.cityClass === 'is-valid' && this.state.stateClass === 'is-valid' && this.state.codeClass === 'is-valid' && this.state.nameClass === 'is-valid' && this.state.emailClass === 'is-valid' && this.state.terms === true) {
        let user = {
          name: name,
          designation: designation,
          company: company,
          photo_url: '',
          email: email.toLowerCase(),
          phone1: phone1,
          phone2: phone2,
          tax_id: taxId,
          distributor_id: null,
          address: { address1: address1, city: city, state: state, postal_code: zipCode },
          roles: ['user'],
          wishlist: [],
        };
        this.setState({ isSubmitDisabled: true });
        const response = await registerUser(user);
        if (response) {
          if (response.status === 201) {
            this.setState({ loading: false, isSubmitDisabled: false });
            await history.push('/thank-you');
          } else {
            if (response.response.data.error === 'email is already in use') {
              toast.error('Email id already exist');
              this.setState({ loading: false, isSubmitDisabled: false });
            } else {
              this.setState({ loading: false, isSubmitDisabled: false });
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        }
      } else {
        this.validator.showMessages();
        toast.error('Please fill all the Mandatory details');
        this.forceUpdate();
        event.preventDefault();
        if (this.state.terms === false) {
          document.getElementById('tAndC').innerHTML = 'Please read and accept the Terms and Conditions';
        }
        this.setState({ loading: false, isSubmitDisabled: false });
      }
    });
  };

  render() {
    const { company, isSubmitDisabled, taxId, address1, city, state, zipCode, phone1, phone2, name, designation, email, terms, companyClass, taxIdClass, cityClass, stateClass, codeClass, nameClass, emailClass, addressClass } = this.state;
    return (
      <div className="align-items-center bg-merck-purple text-white py-5">
        <div className="container-fluid p-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="text-center">
                <h1 className="font-merck display-4 text-merck-yellow">
                  <b className="font-verdana display-4 text-merck-yellow">
                    The Power Tool
                    <br /> Customer Registration Form
                  </b>
                </h1>
              </div>
              <form className="text-merck-yellow">
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Distributor Name</label> <span className="text-danger">*</span>
                      <input type="text" className={`form-control ${companyClass}`} id="companyName" name="company" placeholder="e.g. Merck Philippines." onChange={this.onInputChange} value={company} />
                      <span className="text-danger">{this.validator.message('Company', company, 'required')}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-1">TIN</label> <span className="text-danger">*</span>
                      <InputMask mask="999-999-999-999" className={`form-control ${taxIdClass}`} maskChar={null} value={taxId} placeholder="e.g XXX-XXX-XXX-XXX" onChange={this.onTinChange} beforeMaskedValueChange={this.beforeMaskedValueChange} />
                      <span className="text-danger">{this.validator.message('tin', taxId, ['required', { regex: TAX_PATTERN }])}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Office Address</label> <span className="text-danger">*</span>
                      <input type="text" className={`form-control ${addressClass}`} id="registeredAddress" name="address1" placeholder="e.g. 36/F The Finance Center | 26th Street corner 9th Avenue Bonifacio Global City | 1634 Taguig City" onChange={this.onInputChange} value={address1} />
                      <span className="text-danger">{this.validator.message('Address', address1, 'required')}</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>City</label>
                      <span className="text-danger">*</span>
                      <input type="text" className={`form-control ${cityClass}`} name="city" id="city" placeholder="e.g. Taguig" onChange={this.onInputChange} value={city} />
                      <span className="text-danger">{this.validator.message('City', city, 'required')}</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Province</label> <span className="text-danger">*</span>
                      <input type="text" className={`form-control ${stateClass}`} id="state" name="state" placeholder="e.g. Manila" onChange={this.onInputChange} value={state} />
                      <span className="text-danger">{this.validator.message('Province', state, 'required')}</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Postal Code</label> <span className="text-danger">*</span>
                      <input type="text" maxLength="4" className={`form-control ${codeClass}`} id="zipCode" name="zipCode" placeholder="e.g. 1001" onChange={this.onCodeChange} value={zipCode} />
                      <span className="text-danger">{this.validator.message('Postal Code', zipCode, 'required')}</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Contact Number</label>
                      <input type="tel" className="form-control" id="phoneNumber" name="phone1" onChange={this.onInputChange} value={phone1} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Contact Person</label> <span className="text-danger">*</span>
                      <input type="text" className={`form-control ${nameClass}`} id="name" name="name" placeholder="e.g. John" onChange={this.onInputChange} value={name} />
                      <span className="text-danger">{this.validator.message('Contact Person', name, 'required')}</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input type="tel" className="form-control" id="mobileNumber" name="phone2" onChange={this.onInputChange} value={phone2} />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Title / Designation</label>
                      <input type="text" className="form-control" id="designation" name="designation" placeholder="e.g. Sales Manager" onChange={this.onInputChange} value={designation} />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Email</label> <span className="text-danger">*</span>
                      <input type="email" className={`form-control ${emailClass}`} id="inputEmail1" name="email" placeholder="e.g. john@merckgroup.com" onChange={this.onInputChange} value={email} />
                      <span className="text-danger">{this.validator.message('Email', email, 'required|email')}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input type="checkbox" id="agreement" name="agreement" checked={terms} onChange={this.onTermsAndConditionChange} />
                      <label className="pl-2">
                        By submiting, I accept the {''}
                        <a href="https://firebasestorage.googleapis.com/v0/b/mprice-ph.appspot.com/o/terms_conditions%2FTerms%20%26%20Conditions%20(Indo%20T%26C).pdf?alt=media&token=429c1248-b802-47dc-878e-fbe9c4e63cd2" target="_blank" rel="noopener noreferrer" className="text-merck-pink">
                          Terms &amp; Conditions
                        </a>
                      </label>
                      <br />
                      <span id="tAndC" className="text-merck-pink" />
                    </div>
                  </div>
                </div>
                <button disabled={isSubmitDisabled} type="submit" onClick={this.onSubmit} className="btn btn-merck-yellow">
                  Submit
                </button>
                <Link to="/" className="btn btn-secondary ml-2">
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Register);
