import React, { Component } from 'react';

class Header extends Component {
  render() {
    const { title, section, children } = this.props;
    return (
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h6 className="header-pretitle">{section}</h6>
                <h1 className="header-title text-capitalize">{title}</h1>
              </div>
              <div className="col-auto">{children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
