import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banners from '../data/banners.json';

class Banner extends React.Component {
  render() {
    return (
      <Carousel>
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            {banner.url === '' ? (
              <div className="card">
                <img src={banner.img} alt="slider" className="card-img-top" style={{ maxHeight: 250 }} />
              </div>
            ) : (
              <a href={banner.url} target="_blank" rel="noopener noreferrer">
                <div className="card">
                  <img src={banner.img} alt="slider" className="card-img-top" style={{ maxHeight: 250 }} />
                </div>
              </a>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

export default Banner;
