import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from '../../components/Spinner';
import SimpleReactValidator from 'simple-react-validator';
import { trackPageView, trackEvent } from '../../helpers/analytics';
import { resetPassword } from '../../services/authServices';
toast.configure();

class AppForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Please enter a valid email',
        default: 'This field is required',
      },
    });
    this.state = {
      email: '',
      loading: false,
    };
  }

  componentDidMount() {
    trackPageView();
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    trackEvent('Reset Password', 'Continue');
    const { email } = this.state;
    const { history } = this.props;
    if (email) {
      await this.setState({ loading: true });
      const request = { email: email.toLowerCase().trim(), source: 'app' };
      const response = await resetPassword(request);
      try {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            this.setState({ loading: false }, () => {
              toast.success(`An email as been sent to ${email} with instructions to reset your password.`);
              setTimeout(() => {
                history.push('/');
              }, 1500);
            });
          } else {
            this.setState({ loading: false });
            toast.error('Invalid Email. Please try again.');
          }
        }
      } catch (error) {
        this.setState({ loading: false });
        console.warn(error);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      event.preventDefault();
    }
  };

  render() {
    return (
      <div className="d-flex align-items-center vh-100 bg-merck-purple text-white">
        <div className="container">
          <div className="row align-items-center h-100">
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
              <div className="text-center">
                <img src="/img/reset-password.svg" alt="password-reset" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <Spinner display={this.state.loading}>
                <div className="text-center">
                  <h1 className="font-merck text-merck-yellow mb-3">Forgot Password?</h1>
                  <p className="text-muted mb-3">Enter your email address and we'll send you instructions to reset your password.</p>
                </div>
                <form>
                  <div className="form-group">
                    <input name="email" type="email" className="form-control" value={this.state.email} placeholder="name@address.com" onChange={this.onEmailChange} />
                    {this.validator.message('', this.state.email, 'required')}
                  </div>
                  <button type="submit" className="btn btn-lg btn-block btn-merck-yellow mb-3" onClick={this.onSubmit}>
                    Continue
                  </button>
                  <div className="text-center">
                    <small className="text-muted text-center">
                      Remember your password?{' '}
                      <Link to="/" className="text-merck-red">
                        Sign in
                      </Link>
                      .
                    </small>
                  </div>
                </form>
              </Spinner>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppForgotPassword;
