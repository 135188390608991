import React from 'react';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Banner from '../components/banner';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import { getProducts, updateUserById } from '../../services';
import { trackPageView, trackEvent } from '../../helpers/analytics';
import ContentContainer from '../../admin/components/ContentContainer';

const cartHelper = require('../../helpers/appHelper');

const take = 25;

class Products extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      wishlist: user && user.wishlist !== undefined ? user.wishlist : [],
      products: [],
      keyword: '',
      skip: 0,
      timeout: 0,
      loading: false,
    };
    this.timeoutRef = React.createRef();
  }

  componentDidMount() {
    trackPageView();
    const { distributor } = this.props;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.getProductsList(distributor);
      }
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { keyword, skip, timeout } = this.state;
    const { distributor } = this.props;
    if (prevState.keyword !== keyword || prevState.skip !== skip) {
      if (this.timeoutRef.current !== null) clearTimeout(this.timeoutRef.current);
      this.timeoutRef.current = setTimeout(() => {
        this.timeoutRef.current = null;
        this.getProductsList(distributor);
      }, timeout);
    }
  };

  getProductsList = async (distributor) => {
    const { keyword, skip } = this.state;
    const { history, dispatch } = this.props;
    try {
      const response = await getProducts(keyword, take, skip);
      if (response) {
        if (response.status === 200) {
          const productList = [];
          map(response.data, (product) => {
            map(Object.keys(distributor.business_unit), (item) => {
              let discountedPrice = 0;
              if (item === product.business_unit) {
                const discountPercent = distributor.business_unit[product.business_unit];
                if (discountPercent > 0) {
                  const discountprice = product.price * discountPercent;
                  discountedPrice = product.price - discountprice;
                  product.discounted_price = discountedPrice;
                }
                product.discounted_price = discountedPrice;
                product.asked_price = 0;
                product.asked_discountpercent = 0;
                product.asked_discountprice = 0;
                product.approved_price = 0;
                product.reason = '';
                if (product?.portfolio === "red") {
                  product.discounted_price = 0
                }
                productList.push(product);
              }
            });
          });
          this.setState({ products: productList || [], loading: false, timeout: 500 });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  toggleWishlist = async (product) => {
    let { wishlist } = this.state;
    const { user, dispatch, history } = this.props;
    const { id } = user;
    if (wishlist.includes(product.id)) {
      wishlist = wishlist.filter((id) => id !== product.id);
      toast.success(`${product.name} has been removed from your wishlist.`);
    } else {
      wishlist.push(product.id);
      toast.success(`${product.name} has been added to your wishlist.`);
    }
    await this.setState({ wishlist });
    const updateUserData = {
      wishlist: wishlist,
    };
    const response = await updateUserById(id, updateUserData);
    try {
      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.props.dispatch({
            type: 'EDIT_LOGGED_USER',
            payload: {
              ...updateUserData,
            },
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/');
          }
          if (response.response.status >= 500) {
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
    trackEvent('Products', 'Add to Wishlist', `${product.name} (${product.id})`);
  };

  addToCart = (product) => {
    const { cart, user } = this.props;
    const { id } = user;
    const updatedCart = cartHelper.addToCart(product, 1, cart, id);
    toast.success(`${product.name} has been added to the cart.`);
    this.props.dispatch({ type: 'CART', payload: updatedCart });
    trackEvent('Products', 'Add to Cart', `${product.name} (${product.id})`);
  };

  onSearch = ({ target: { value } }) => {
    this.setState({
      keyword: value,
      skip: 0,
    });
  };

  handlePrevious = () => {
    const { skip } = this.state;
    const currentPage = skip - take;
    this.setState({ skip: currentPage });
  };

  handleNext = () => {
    const { skip } = this.state;
    const currentPage = skip + take;
    this.setState({ skip: currentPage });
  };

  render() {
    const { products, keyword, skip, wishlist, loading } = this.state;
    return (
      <>
        <Banner />
        <Spinner display={loading}>
          <div className="container-fluid">
            <div className="card mt-3" data-toggle="lists">
              <div className="card-header">
                <form>
                  <div className="input-group input-group-flush">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                    <input
                      className="form-control search"
                      type="search"
                      placeholder="Search by Material/Layout number/Business Unit"
                      onChange={this.onSearch}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    />
                  </div>
                </form>
              </div>
              <ContentContainer size={products && products.length} isKeywordAndNoDataFound={!products.length && keyword}>
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>Material</th>
                        <th className="text-center">Shelf Life (in Days)</th>
                        <th className="text-center">List Price</th>
                        {/* <th className="text-center">Limit Price</th> */}
                        <th className="text-center">Discounted Price </th>
                        <th className="text-center">Lead Time (in Days)</th>
                        <th className="text-center">Quote Validity (in Days)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {products &&
                        products.map((product) => (
                          <tr key={product.id}>
                            <td style={{ maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} data-toggle="tooltip" title={product.name}>
                              {product?.name}
                              <br />
                              <span className="small text-muted">{product?.id}</span>
                              <div>
                                <b>Business Unit: </b> <span className="small text-muted">{product?.business_unit}</span>
                              </div>
                              <div>
                                <b>Unit: </b> <span className="small text-muted">{product?.business_unit}</span>
                              </div>
                              <div>
                                <b>Distributor Note: </b> <span className="small text-muted">{product?.distributor_note}</span>
                              </div>
                              <div>
                                <b>Customer Note: </b> <span className="small text-muted">{product?.customer_note}</span>
                              </div>
                              {product.cell_line &&
                                <div>
                                  <b>Additional Note: </b> <span className="small text-muted">Cell line products will have additional freight charges on top of list price</span>
                                </div>
                              }
                            </td>
                            <td className="text-center">{product?.self_life ? `${product?.self_life}` : '-'}</td>
                            <td className="text-center">
                              <Amount value={product.price} />
                            </td>
                            {/* <td className="text-center">
                              <Amount value={product.limit_price} />
                            </td> */}
                            <td className="text-center">{product?.discounted_price > 0 ? <Amount value={product?.discounted_price} /> : '-'}</td>
                            <td className="text-center">{product?.leadtime}</td>
                            <td className="text-center">60</td>

                            <td className="text-right">
                              {wishlist.includes(product?.id) ? (
                                <>
                                  <button className="btn btn-sm btn-danger ml-2" onClick={() => this.toggleWishlist(product)} title="Wishlist">
                                    <i className="fe fe-heart" />
                                    <span className="d-none d-md-none d-lg-none d-xl-inline"></span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className="btn btn-sm btn-outline-danger ml-2" onClick={() => this.toggleWishlist(product)} data-toggle="tooltip" data-placement="top" title="Add to Wishlist">
                                    <i className="fe fe-heart" />
                                    <span className="d-none d-md-none d-lg-none d-xl-inline"></span>
                                  </button>
                                </>
                              )}
                              <button className="btn btn-sm btn-success ml-2" onClick={() => this.addToCart(product)} data-toggle="tooltip" data-placement="top" title="Add to Cart">
                                <i className="fe fe-shopping-cart mr-1" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </ContentContainer>
            </div>

            {/* Pagination */}
            <Pagination size="sm" className="justify-content-center">
              <Pagination.Item
                disabled={skip === 0}
                onClick={() => {
                  this.handlePrevious();
                }}
              >
                Previous
              </Pagination.Item>
              <Pagination.Item disabled={true}>Page {skip / take + 1}</Pagination.Item>
              <Pagination.Item
                disabled={products.length < 0}
                onClick={() => {
                  this.handleNext();
                }}
              >
                Next
              </Pagination.Item>
            </Pagination>
          </div>
        </Spinner>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
    distributor: state.distributor,
  };
};

export default connect(mapStateToProps)(Products);
