import React from 'react';
import 'firebase/auth';
import shortid from 'shortid';
import Firebase from '../../Firebase';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Spinner from '../../components/Spinner';
import SimpleReactValidator from 'simple-react-validator';
import { TAX_PATTERN } from '../../admin/constants/Constants';
import { getUserById, updateUserById } from '../../services';
import { trackPageView, trackEvent } from '../../helpers/analytics';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address1: '',
      city: '',
      name: '',
      email: '',
      value: '',
      phone1: '',
      phone2: '',
      state: '',
      company: '',
      zipCode: '',
      photoUrl: '',
      designation: '',
      loading: false,
      imageLoading: false,
      src: null,
      crop: {
        unit: '%',
        width: 50,
        height: 50,
        aspect: 4 / 4,
      },
      companyClass: 'is-invalid',
      taxIdClass: 'is-invalid',
      addressClass: 'is-invalid',
      cityClass: 'is-invalid',
      stateClass: 'is-invalid',
      codeClass: 'is-invalid',
      nameClass: 'is-invalid',
      emailClass: 'is-invalid',
      showImageCropping: true,
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getUserData();
    trackPageView();
  }

  getUserData = async () => {
    const { user, dispatch, history } = this.props;
    const { id } = user;
    await this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          const response = await getUserById(id);
          if (response && response.status === 200) {
            let userData = response.data;
            let emailPattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            await this.setState(
              {
                company: userData.company,
                address1: userData.address.address1,
                city: userData.address.city,
                state: userData.address.state,
                zipCode: userData.address.postal_code,
                name: userData.name,
                designation: userData.designation,
                email: userData.email,
                phone1: userData.phone1,
                phone2: userData.phone2,
                value: userData.tax_id,
                photoUrl: userData.photo_url === undefined ? '' : userData.photo_url,

                companyClass: userData.company.length <= 0 ? 'is-invalid' : 'is-valid',
                addressClass: userData.address.address1.length <= 0 ? 'is-invalid' : 'is-valid',
                cityClass: userData.address.city.length <= 0 ? 'is-invalid' : 'is-valid',
                stateClass: userData.address.state.length <= 0 ? 'is-invalid' : 'is-valid',
                nameClass: userData.name.length <= 0 ? 'is-invalid' : 'is-valid',
                codeClass: userData.address.postal_code.length <= 0 ? 'is-invalid' : 'is-valid',
                taxIdClass: userData.tax_id.length <= 0 ? 'is-invalid' : 'is-valid',
                emailClass: emailPattern.test(userData.email) ? 'is-valid' : 'is-invalid',
              },
              () => {
                this.setState({ loading: false });
              }
            );
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
              await dispatch({ type: 'USER', payload: {} });
              await localStorage.removeItem('token');
              history.push('/');
            }
            this.setState({ loading: false });
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
          }
        } catch (error) {
          console.warn(error);
          this.setState({ loading: false });
        }
      }
    );
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    switch (event.target.name) {
      case 'address1':
        this.setState(
          {
            addressClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.addressClass === 'is-invalid') {
              this.validator.showMessages('address1');
              this.forceUpdate();
            }
          }
        );
        break;

      case 'city':
        this.setState(
          {
            cityClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.cityClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      case 'name':
        this.setState(
          {
            nameClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.nameClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;
      case 'state':
        this.setState(
          {
            stateClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
          },
          () => {
            if (this.state.stateClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      case 'email':
        let emailPattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        this.setState(
          {
            emailClass: emailPattern.test(event.target.value) ? 'is-valid' : 'is-invalid',
          },
          () => {
            if (this.state.emailClass === 'is-invalid') {
              this.validator.showMessages();
              this.forceUpdate();
            }
          }
        );
        break;

      default:
        break;
    }
  };

  onCodeChange = ({ target: { name, value } }) => {
    if (!isNaN(Number(value))) {
      this.setState({ [name]: value });
      this.setState(
        {
          codeClass: value.length <= 0 ? 'is-invalid' : 'is-valid',
        },
        () => {
          if (this.state.codeClass === 'is-invalid') {
            this.validator.showMessages();
            this.forceUpdate();
          }
        }
      );
    }
  };

  onImageUpload = (e) => {
    if (e.target.files[0]) {
      this.setState({
        showImageCropping: true,
      });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          src: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ file: e.target.files[0].name });
    }
  };

  // Image loaded for cropping
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  //on cropping completed
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  //On changing cropping dimension
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  //Copped image url is ready
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop);
    }
  }

  //Get copped image url
  async getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = this.state.file;

        let fileExtension = blob.name.toLowerCase().split('.').pop();
        let fileName = `${shortid.generate()}.${fileExtension}`;
        let fileReference = `users/${fileName}`;
        var metadata = { contentType: `image/${fileExtension}` };

        let storage = Firebase.storage().ref();
        let task = storage.child(fileReference).put(blob, metadata);

        this.setState({ imageLoading: true }, () => {
          task.on(
            Firebase.storage.TaskEvent.STATE_CHANGED,
            (uploadedFile) => {
              // Upload Progress
            },
            (error) => {
              // Upload Error
              console.log(error);
              this.setState({ imageLoading: false }, () => {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact support@merckgroup.com');
              });
            },
            () => {
              // Upload Complete
              task.snapshot.ref.getDownloadURL().then((fileUrl) => {
                this.setState({
                  croppedImage: fileUrl,
                  imageLoading: false,
                  photoUrl: fileUrl,
                });
              });
              resolve(this.state.croppedImage);
            }
          );
        });
      }, 'image/jpeg');
    });
  }

  //Trigger storing cropped image url
  getCropImg = () => {
    this.setState(
      {
        photoUrl: this.state.croppedImage,
      },
      () => {
        this.setState({
          showImageCropping: false,
          croppedImage: '',
        });
      }
    );
  };

  onTinChange = (event) => {
    let taxIdPattern = TAX_PATTERN;
    this.setState(
      {
        value: event.target.value,
        taxIdClass: taxIdPattern.test(event.target.value) ? 'is-valid' : 'is-invalid',
      },
      () => {
        if (this.state.taxIdClass === 'is-invalid') {
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
    );
  };

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  onSubmit = async (event) => {
    const { user, dispatch, history } = this.props;
    const { id } = user;
    event.preventDefault();
    trackEvent('Profile', 'Save changes');
    let { address1, city, name, designation, email, zipCode, value, phone1, phone2, state, photoUrl } = this.state;
    let emailPattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    if (this.state.addressClass === 'is-valid' && this.state.cityClass === 'is-valid' && this.state.companyClass === 'is-valid' && this.state.nameClass === 'is-valid' && this.state.codeClass === 'is-valid' && this.state.emailClass === 'is-valid' && this.state.taxIdClass === 'is-valid' && this.state.stateClass === 'is-valid') {
      const userData = {
        address: {
          address1: address1,
          city: city,
          state: state,
          zipCode: zipCode,
        },
        name: name,
        designation: designation,
        email: email.toLowerCase().trim(),
        phone1: phone1,
        phone2: phone2,
        tax_id: value,
        photo_url: photoUrl,
      };
      const response = await updateUserById(id, userData);
      try {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            dispatch({
              type: 'EDIT_LOGGED_USER',
              payload: {
                ...userData,
              },
            });
            await toast.success('Your account has been edited successfully.');
            this.setState({ company: userData.company, address1: userData.address.address1, city: userData.address.city, state: userData.address.state, zipCode: userData.address.postal_code, name: userData.name, designation: userData.designation, email: userData.email, phone: userData.phone, fax: userData.fax, value: userData.tax_id, photoUrl: userData.photo_url === undefined ? '' : userData.photo_url, companyClass: userData.company.length <= 0 ? 'is-invalid' : 'is-valid', addressClass: userData.address.address1.length <= 0 ? 'is-invalid' : 'is-valid', cityClass: userData.address.city.length <= 0 ? 'is-invalid' : 'is-valid', stateClass: userData.address.state.length <= 0 ? 'is-invalid' : 'is-valid', nameClass: userData.name.length <= 0 ? 'is-invalid' : 'is-valid', designationClass: userData.designation.length <= 0 ? 'is-invalid' : 'is-valid', taxIdClass: userData.tax_id.length <= 0 ? 'is-invalid' : 'is-valid', emailClass: emailPattern.test(userData.email) ? 'is-valid' : 'is-invalid' });
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
              await dispatch({ type: 'USER', payload: {} });
              await localStorage.removeItem('token');
              history.push('/');
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
          }
        }
      } catch (error) {
        console.warn(error);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      event.preventDefault();
      if (this.state.termsAndConditionAccepted === false) {
        document.getElementById('tAndC').innerHTML = 'T&C should be accepted';
      }
    }
  };

  render() {
    const { src, crop, photoUrl, imageLoading, company, value, address1, city, state, zipCode, phone1, phone2, name, designation, email, companyClass, taxIdClass, cityClass, stateClass, codeClass, nameClass, emailClass, addressClass } = this.state;
    return (
      <>
        <div className="container-fluid my-3">
          <Spinner display={this.state.loading}>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <form onSubmit={this.onSubmit}>
                  <div className="row justify-content-between align-items-center mt-5">
                    <div className="col">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="avatar avatar-xxl mb-2">{imageLoading === true ? <div className="spinner-border mb-3" role="status" /> : <img src={photoUrl.length > 0 ? photoUrl : '/img/user.png'} alt="user" className="avatar-img rounded-circle mb-3" />}</div>
                        </div>
                        <div className="col ml-n2">
                          <h4>Profile Image</h4>
                          <div className="form-group">
                            <input accept="image/*" id="flat-button-file" type="file" onChange={this.onImageUpload} style={{ cursor: 'pointer' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    {src && this.state.showImageCropping && <ReactCrop style={{ width: '175px' }} src={src} crop={crop} ruleOfThirds onImageLoaded={this.onImageLoaded} onComplete={this.onCropComplete} onChange={this.onCropChange} />}
                    {this.state.croppedImage && (
                      <div className="justify-content-center">
                        <button className="btn btn-primary" onClick={this.getCropImg}>
                          Apply
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row mt-5">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="companyName">Distributor Name</label>
                        <span className="text-merck-pink">*</span>
                        <input disabled type="text" className={`form-control ${companyClass}`} id="companyName" name="company" placeholder="e.g. Merck Philippines." value={company} style={{ backgroundColor: '#F1F2F3' }} />
                        <span className="text-danger">{this.validator.message('Company', company, 'required')}</span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="taxId" className="mb-1">
                          TIN
                        </label>
                        <span className="text-merck-pink">*</span>
                        <InputMask mask="999-999-999-999" className={`form-control ${taxIdClass}`} maskChar={null} value={value} placeholder="e.g XXX-XXX-XXX-XXX" onChange={this.onTinChange} beforeMaskedValueChange={this.beforeMaskedValueChange} />
                        <span className="text-danger">{this.validator.message('tin', value, ['required', { regex: TAX_PATTERN }])}</span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="registeredAddress">Office Address</label>
                        <span style={{ color: 'red' }}>*</span>
                        <input type="text" className={`form-control ${addressClass}`} id="registeredAddress" name="address1" placeholder="e.g. 36/F The Finance Center | 26th Street corner 9th Avenue Bonifacio Global City | 1634 Taguig City" onChange={this.onInputChange} value={address1} />
                        <span className="text-danger">{this.validator.message('Address', address1, 'required')}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="city">City</label>
                        <span style={{ color: 'red' }}>*</span>
                        <input type="text" className={`form-control ${cityClass}`} name="city" id="city" placeholder="e.g. Taguig" onChange={this.onInputChange} value={city} />
                        <span className="text-danger">{this.validator.message('City', city, 'required')}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="state">Province</label>
                        <span style={{ color: 'red' }}>*</span>
                        <input type="text" className={`form-control ${stateClass}`} id="state" name="state" placeholder="e.g. Manila" onChange={this.onInputChange} value={state} />
                        <span className="text-danger">{this.validator.message('State', state, 'required')}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Postal Code</label> <span className="text-danger">*</span>
                        <input type="text" maxLength="4" className={`form-control ${codeClass}`} id="zipCode" name="zipCode" placeholder="e.g. 1001" onChange={this.onCodeChange} value={zipCode} />
                        <span className="text-danger">{this.validator.message('Postal Code', zipCode, 'required')}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input type="tel" className="form-control" id="phoneNumber" name="phone1" onChange={this.onInputChange} value={phone1} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Contact Person</label> <span className="text-danger">*</span>
                        <input type="text" className={`form-control ${nameClass}`} id="name" name="name" placeholder="e.g. John" onChange={this.onInputChange} value={name} />
                        <span className="text-danger">{this.validator.message('Contact Person', name, 'required')}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Mobile Number</label>
                        <input type="tel" className="form-control" id="mobileNumber" name="phone2" onChange={this.onInputChange} value={phone2} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="designation">Title / Designation</label>
                        <input type="text" className="form-control" id="designation" name="designation" placeholder="e.g. Sales Manager" onChange={this.onInputChange} value={designation} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="inputEmail1">Email</label>
                        <span style={{ color: 'red' }}>*</span>
                        <input disabled type="email" className={`form-control ${emailClass}`} id="inputEmail1" name="email" placeholder="e.g. john@merckgroup.com" onChange={this.onInputChange} value={email} style={{ backgroundColor: '#F1F2F3' }} />
                        <span className="text-danger">{this.validator.message('Email', email, 'required|email')}</span>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-merck-pink" disabled={imageLoading ? true : false}>
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </Spinner>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Profile);
