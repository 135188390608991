import { isEmpty, filter } from 'lodash';

export function addToCart(product, quantity, cart, userId) {
  let myCart = [];
  if (cart !== undefined) myCart = [...cart];
  let updatedCart = [];
  const checkProduct = filter(myCart, (item) => item.id === product.id && item.userId === userId);
  if (checkProduct.length > 0) {
    myCart.forEach((item) => {
      if (item.id === product.id && item.userId === userId) item.quantity = item.quantity + quantity;
      item.sub_total = Number(item.asked_price) > 0 ? Number(item.asked_price) * item.quantity : item.discounted_price > 0 ? item.discounted_price * item.quantity : item.price * item.quantity;
      if (item.quantity > 0) updatedCart.push(item);
    });
  } else {
    product.userId = userId;
    product.quantity = quantity;
    product.sub_total = Number(product.asked_price) > 0 ? Number(product.asked_price) * product.quantity : product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
    updatedCart = myCart;
    updatedCart.push(product);
  }
  return updatedCart;
}

export function removeFromCart(product, cart, userId) {
  let updatedCart = [];
  let myCart = [];
  if (cart !== undefined) myCart = [...cart];
  myCart.forEach((item) => {
    if (item.id === product.id && item.userId === userId) item.quantity = 0;
    if (item.quantity > 0) updatedCart.push(item);
  });
  return updatedCart;
}

export function updateCart(product, cart) {
  let updatedCart = [];
  cart.forEach((item) => {
    if (item.id === product.id) {
      updatedCart.push(product);
    } else {
      updatedCart.push(item);
    }
  });
  return updatedCart;
}

export function customerDownloadQuote(cart, user, quoteId, shipToAddress, freight, quoteDate, request_type, quoteValidity) {
  const saveAs = require('file-saver');
  const xl = require('excel4node');
  let wb = new xl.Workbook();
  let headerStyle = wb.createStyle({ font: { size: 18 } });
  let lightCellStyle = wb.createStyle({ font: { size: 11 } });
  let darkCellStyle = wb.createStyle({ font: { size: 11 }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
  let tableHeaderStyle = wb.createStyle({ font: { size: 11, bold: true }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
  let tableItemStyle = wb.createStyle({ font: { size: 11 } });
  let moneyItemStyle = wb.createStyle({ font: { size: 11 }, numberFormat: '₱#,##0.00; (₱#,##0.00); -' });

  const products = cart;
  const fileName = quoteId ? `${quoteId}` : 'Quote Request';
  if (products.length > 0) {
    let ws = wb.addWorksheet('Sheet1');
    ws.column(1).setWidth(25);
    ws.column(2).setWidth(35);
    ws.column(3).setWidth(35);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(25);
    ws.column(6).setWidth(20);
    ws.column(7).setWidth(25);
    ws.column(8).setWidth(20);
    ws.column(9).setWidth(18);
    ws.column(10).setWidth(25);
    ws.column(11).setWidth(40);
    ws.column(12).setWidth(15);
    ws.column(13).setWidth(10);
    ws.column(14).setWidth(15);
    ws.column(15).setWidth(15);
    // Header
    ws.cell(1, 1).string(`Quote Request`).style(headerStyle);
    ws.cell(3, 1).string('Customer Name').style(lightCellStyle);
    ws.cell(3, 2).string(user.company).style(darkCellStyle);
    ws.cell(4, 1).string('Address').style(lightCellStyle);
    ws.cell(4, 2).string(`${user.address.address1}, ${user.address.city}, ${user.address.state} ${user.address.postal_code}`).style(darkCellStyle);
    ws.cell(5, 1).string('Telephone Number').style(lightCellStyle);
    ws.cell(5, 2).string(user.phone2).style(darkCellStyle);
    ws.cell(6, 1).string('Email').style(lightCellStyle);
    ws.cell(6, 2).string(user.email).style(darkCellStyle);
    ws.cell(7, 1).string('Person-in-charge').style(lightCellStyle);
    ws.cell(7, 2).string(user.name).style(darkCellStyle);
    ws.cell(8, 1).string('Quote Request Number').style(lightCellStyle);
    ws.cell(8, 2).string(quoteId).style(darkCellStyle);
    ws.cell(9, 1).string('Quote Date').style(lightCellStyle);
    ws.cell(9, 2).string(quoteDate).style(darkCellStyle);
    ws.cell(10, 1).string('Quote Validity').style(lightCellStyle);
    ws.cell(10, 2).string('60 days').style(darkCellStyle);
    ws.cell(11, 1).string('Ship to Address').style(lightCellStyle);
    ws.cell(11, 2)
      .string(isEmpty(shipToAddress) === false ? `${shipToAddress.address1}, ${shipToAddress.city}, ${shipToAddress.state} ${shipToAddress.postal_code}` : '')
      .style(darkCellStyle);
    // ws.cell(12, 1).string('Request Type').style(lightCellStyle);
    // ws.cell(12, 2).string(request_type).style(darkCellStyle);
    ws.cell(12, 1).string('Layout').style(tableHeaderStyle);
    ws.cell(12, 2).string('Description').style(tableHeaderStyle);
    ws.cell(12, 3).string('Business Unit').style(tableHeaderStyle);
    ws.cell(12, 4).string('Shelf Life (in days)').style(tableHeaderStyle);
    ws.cell(12, 5).string('Commercial Lead Time (in days)').style(tableHeaderStyle);
    ws.cell(12, 6).string('Qty Order').style(tableHeaderStyle);
    ws.cell(12, 7).string('Price List').style(tableHeaderStyle);
    ws.cell(12, 8).string('Discounted Price').style(tableHeaderStyle);
    ws.cell(12, 9).string('Asked Price').style(tableHeaderStyle);
    ws.cell(12, 10).string('Approved Price').style(tableHeaderStyle);
    ws.cell(12, 11).string('Remarks').style(tableHeaderStyle);
    ws.cell(12, 12).string('Total').style(tableHeaderStyle);
    for (let c = 3; c <= 12; c++) {
      for (let r = 3; r <= 10; r++) {
        ws.cell(r, c).style(darkCellStyle);
      }
    }
    let subTotal = 0;
    let tax = 0;
    let total = 0;
    for (let j = 0; j < products.length; j++) {
      const product = products[j];
      let row = 13 + j;
      ws.cell(row, 1).string(product?.id).style(tableItemStyle);
      ws.cell(row, 2).string(product?.name).style(tableItemStyle);
      ws.cell(row, 3).string(product?.business_unit).style(tableItemStyle);
      ws.cell(row, 4).string(product?.self_life).style(tableItemStyle);
      ws.cell(row, 5).number(product?.leadtime || 0).style(tableItemStyle);
      ws.cell(row, 6).number(product?.quantity||0).style(tableItemStyle);
      ws.cell(row, 7).number(product?.price||0).style(moneyItemStyle);
      ws.cell(row, 8).number(product?.discounted_price||0).style(moneyItemStyle);
      ws.cell(row, 9).number(product?.asked_price || 0).style(moneyItemStyle);
      ws.cell(row, 10).number(product?.approved_price || 0).style(moneyItemStyle);
      ws.cell(row, 11).string(product?.comment).style(tableItemStyle);
      ws.cell(row, 12).number(product?.sub_total||0).style(moneyItemStyle);
      subTotal = subTotal + product?.sub_total;
    }

    // commented logic based on client request changes
    // let freightCharges = request_type === 'Delivery' ? (0.5 / 100) * subTotal : request_type === 'Pick-Up' ? (0.15 / 100) * subTotal : 0;
    // freightCharges = freight > 0 ? `${freight}` : freightCharges;
    // freightCharges = Number(freightCharges);
    // const totalForTax = subTotal + freightCharges;
    const totalForTax = subTotal + 0;
    tax = (12 / 100) * totalForTax;
    //total = subTotal + freightCharges + tax;
    total = subTotal + 0 + tax;
    ws.cell(13 + products.length, 11)
      .string('Sub Total')
      .style(tableHeaderStyle);
    ws.cell(13 + products.length, 12)
      .number(subTotal)
      .style(moneyItemStyle);
    // ws.cell(15 + products.length, 11)
    //   .string('Freight')
    //   .style(tableHeaderStyle);
    // ws.cell(15 + products.length, 12)
    //   .number(freightCharges)
    //   .style(moneyItemStyle);
    ws.cell(14 + products.length, 11)
      .string('Tax (12%)')
      .style(tableHeaderStyle);
    ws.cell(14 + products.length, 12)
      .number(tax)
      .style(moneyItemStyle);
    ws.cell(15 + products.length, 11)
      .string('Grand Total')
      .style(tableHeaderStyle);
    ws.cell(15 + products.length, 12)
      .number(total)
      .style(moneyItemStyle);
  }
  wb.writeToBuffer().then(function (buffer) {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  });
}

export function AdminDownloadQuote(cart, user, quoteId, shipToAddress, freight, quoteDate, request_type, quoteValidity) {
  const saveAs = require('file-saver');
  const xl = require('excel4node');
  let wb = new xl.Workbook();
  let headerStyle = wb.createStyle({ font: { size: 18 } });
  let lightCellStyle = wb.createStyle({ font: { size: 11 } });
  let darkCellStyle = wb.createStyle({ font: { size: 11 }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
  let tableHeaderStyle = wb.createStyle({ font: { size: 11, bold: true }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
  let tableItemStyle = wb.createStyle({ font: { size: 11 } });
  let moneyItemStyle = wb.createStyle({ font: { size: 11 }, numberFormat: '₱#,##0.00; (₱#,##0.00); -' });
  let discountStyle = wb.createStyle({ font: { size: 11 }, color: '#ff0000', numberFormat: '0.00%; (0.00%); -' });

  const products = cart;
  const fileName = quoteId ? `${quoteId}` : 'Quote Request';
  if (products.length > 0) {
    let ws = wb.addWorksheet('Sheet1');
    ws.column(1).setWidth(25);
    ws.column(2).setWidth(35);
    ws.column(3).setWidth(35);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(25);
    ws.column(6).setWidth(20);
    ws.column(7).setWidth(25);
    ws.column(8).setWidth(20);
    ws.column(9).setWidth(18);
    ws.column(10).setWidth(15);
    ws.column(11).setWidth(25);
    ws.column(12).setWidth(15);
    ws.column(13).setWidth(10);
    ws.column(15).setWidth(10);
    ws.column(16).setWidth(15);
    ws.column(17).setWidth(15);
    // Header
    ws.cell(1, 1).string(`Quote Request`).style(headerStyle);
    ws.cell(3, 1).string('Customer Name').style(lightCellStyle);
    ws.cell(3, 2).string(user.company).style(darkCellStyle);
    ws.cell(4, 1).string('Address').style(lightCellStyle);
    ws.cell(4, 2).string(`${user.address.address1}, ${user.address.city}, ${user.address.state} ${user.address.postal_code}`).style(darkCellStyle);
    ws.cell(5, 1).string('Telephone Number').style(lightCellStyle);
    ws.cell(5, 2).string(user.phone2).style(darkCellStyle);
    ws.cell(6, 1).string('Email').style(lightCellStyle);
    ws.cell(6, 2).string(user.email).style(darkCellStyle);
    ws.cell(7, 1).string('Person-in-charge').style(lightCellStyle);
    ws.cell(7, 2).string(user.name).style(darkCellStyle);
    ws.cell(8, 1).string('Quote Request Number').style(lightCellStyle);
    ws.cell(8, 2).string(quoteId).style(darkCellStyle);
    ws.cell(9, 1).string('Quote Date').style(lightCellStyle);
    ws.cell(9, 2).string(quoteDate).style(darkCellStyle);
    ws.cell(10, 1).string('Quote Validity').style(lightCellStyle);
    ws.cell(10, 2).string('60 days').style(darkCellStyle);
    ws.cell(11, 1).string('Ship to Address').style(lightCellStyle);
    ws.cell(11, 2)
      .string(isEmpty(shipToAddress) === false ? `${shipToAddress.address1}, ${shipToAddress.city}, ${shipToAddress.state} ${shipToAddress.postal_code}` : '')
      .style(darkCellStyle);
    // ws.cell(12, 1).string('Request Type').style(lightCellStyle);
    // ws.cell(12, 2).string(request_type).style(darkCellStyle);
    ws.cell(12, 1).string('Layout').style(tableHeaderStyle);
    ws.cell(12, 2).string('Description').style(tableHeaderStyle);
    ws.cell(12, 3).string('Business Unit').style(tableHeaderStyle);
    ws.cell(12, 4).string('Shelf Life (in days)').style(tableHeaderStyle);
    ws.cell(12, 5).string('Commercial Lead Time (in days)').style(tableHeaderStyle);
    ws.cell(12, 6).string('Qty Order').style(tableHeaderStyle);
    ws.cell(12, 7).string('Price List').style(tableHeaderStyle);
    ws.cell(12, 8).string('Discounted Price').style(tableHeaderStyle);
    ws.cell(12, 9).string('Asked Price').style(tableHeaderStyle);
    ws.cell(12, 10).string('Approved Price').style(tableHeaderStyle);
    ws.cell(12, 11).string('Discount').style(tableHeaderStyle);
    ws.cell(12, 12).string('Total').style(tableHeaderStyle);
    for (let c = 3; c <= 12; c++) {
      for (let r = 3; r <= 10; r++) {
        ws.cell(r, c).style(darkCellStyle);
      }
    }
    let subTotal = 0;
    let tax = 0;
    let total = 0;
    for (let j = 0; j < products.length; j++) {
      const product = products[j];
      const price = product.approved_price || product.asked_price || product.discounted_price || product.price || 0
      const discount = Number((((product.price - price) / product.price))) || 0
      let row = 13 + j;
      ws.cell(row, 1).string(product.id).style(tableItemStyle);
      ws.cell(row, 2).string(product.name).style(tableItemStyle);
      ws.cell(row, 3).string(product?.business_unit).style(tableItemStyle);
      ws.cell(row, 4).string(product?.self_life).style(tableItemStyle);
      ws.cell(row, 5).number(product?.leadtime || 0).style(tableItemStyle);
      ws.cell(row, 6).number(product?.quantity||0).style(tableItemStyle);
      ws.cell(row, 7).number(product?.price||0).style(moneyItemStyle);
      ws.cell(row, 8).number(product?.discounted_price || 0).style(moneyItemStyle);
      ws.cell(row, 9).number(product?.asked_price || 0).style(moneyItemStyle);
      ws.cell(row, 10).number(product?.approved_price || 0).style(moneyItemStyle);
      ws.cell(row, 11).number(discount || 0).style(discountStyle);
      ws.cell(row, 12).number(product?.sub_total || 0).style(moneyItemStyle);
      subTotal = subTotal + product?.sub_total;
    }

    // let freightCharges = request_type === 'Delivery' ? (0.5 / 100) * subTotal : request_type === 'Pick-Up' ? (0.15 / 100) * subTotal : 0;
    // freightCharges = freight > 0 ? `${freight}` : freightCharges;
    // freightCharges = Number(freightCharges);
    // const totalForTax = subTotal + freightCharges;
    const totalForTax = subTotal + 0;
    tax = (12 / 100) * totalForTax;
    //total = subTotal + freightCharges + tax;
    total = subTotal + 0 + tax;
    ws.cell(13 + products.length, 11)
      .string('Sub Total')
      .style(tableHeaderStyle);
    ws.cell(13 + products.length, 12)
      .number(subTotal)
      .style(moneyItemStyle);
    // ws.cell(15 + products.length, 11)
    //   .string('Freight')
    //   .style(tableHeaderStyle);
    // ws.cell(15 + products.length, 12)
    //   .number(freightCharges)
    //   .style(moneyItemStyle);
    ws.cell(14 + products.length, 11)
      .string('Tax (12%)')
      .style(tableHeaderStyle);
    ws.cell(14 + products.length, 12)
      .number(tax)
      .style(moneyItemStyle);
    ws.cell(15 + products.length, 11)
      .string('Grand Total')
      .style(tableHeaderStyle);
    ws.cell(15 + products.length, 12)
      .number(total)
      .style(moneyItemStyle);
  }
  wb.writeToBuffer().then(function (buffer) {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  });
}

export function groupBy(array, f) {
  let groups = {};
  array.forEach((o) => {
    let group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
}
