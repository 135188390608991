import React from 'react';
import { Link } from 'react-router-dom';
import { trackPageView } from '../../helpers/analytics';

class ThankYou extends React.Component {
  componentDidMount() {
    trackPageView();
  }

  render() {
    return (
      <div className="d-flex align-items-center vh-100 bg-merck-purple text-white">
        <div className="container">
          <div className="row align-items-center h-100">
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
              <div className="text-center">
                <img src="/img/mail-sent.svg" alt="password-reset" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <h1 className="font-merck text-merck-yellow mb-3">Thank You</h1>
              <br />
              <p className="text-muted mb-3">
                Your form was submitted successfully.You will be receiving an email from our team once your account is active. If already activated, click{' '}
                <Link to="/" className="text-danger">
                  here
                </Link>{' '}
                to login.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ThankYou;
