import React, { Component } from 'react';

class PublicLayout extends Component {
  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default PublicLayout;
