import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import Swal from 'sweetalert';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import Container from 'react-bootstrap/Container';

const ResetPassword = (props) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${props.match.params.token}`;
  const initialValues = {
    password: '',
    confirmPassword: '',
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmShowPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/, 'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const [loading, setLoading] = useState(false);

  const resetPassword = async (event) => {
    const { password } = event;
    setLoading(true);
    try {
      const response = await axios.post('me/update-password', { password: password });
      if ((response && response.status === 200) || (response && response.status === 201)) {
        toast.info(`Your password has been updated, please login to continue`);
        setLoading(false);
        props.history.push('/');
      }
    } catch (error) {
      setLoading(false);
      updateStatus();
    }
  };

  const updateStatus = () => {
    Swal({
      title: '',
      text: 'Try resetting your password again, as your request to verify your password has expired or the link has already been used',
      icon: 'warning',
      buttons: ['Cancel', 'Resend Reset Email'],
      modal: false,
    }).then(async (status) => {
      if (status) {
        props.history.push('/forgot-password');
      }
    });
  };
  return (
    <>
      <div className="d-flex align-items-center border-top border-top-2 bg-merck-purple text-white vh-100" style={{ display: 'block' }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-12 col-md-5 col-xl-4 my-5">
              <div className="text-center">
                <h1 className="font-merck text-merck-yellow mb-3">Reset Password</h1>
                <p className="mb-5">Enter a secure password to reset your account</p>
              </div>
              <Spinner display={loading}>
                <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={resetPassword}>
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group>
                        <div className="input-group input-group-merge">
                          <Form.Control type={showPassword ? 'text' : 'password'} name="password" placeholder="Enter Password" className="form-control-appended" value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                          <div className="input-group-append">
                            <span className="input-group-text" onClick={toggleShowPassword}>
                              <i className={showPassword ? 'fe fe-eye' : 'fe fe-eye-off'} />
                            </span>
                          </div>
                          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <div className="input-group input-group-merge">
                          <Form.Control type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" placeholder="Confirm password" className="form-control-appended" value={values.confirmPassword} onChange={handleChange} isInvalid={!!errors.confirmPassword} />
                          <div className="input-group-append">
                            <span className="input-group-text" onClick={toggleConfirmShowPassword}>
                              <i className={showConfirmPassword ? 'fe fe-eye' : 'fe fe-eye-off'} />
                            </span>
                          </div>
                          <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <button type="submit" className="btn btn-lg btn-block btn-merck-yellow mb-3">
                        Reset Password
                      </button>
                      <div className="text-center">
                        Remember your password?{' '}
                        <Link to="/" className="text-merck-pink">
                          Sign In
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Spinner>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ResetPassword);
