import React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Public
import PublicLayout from './components/PublicLayout';
import AppRoute from './components/AppRoute';
import PageNotFound from './components/PageNotFound';

// Admin
import AdminLayout from './admin/components/AdminLayout';
import AdminLogin from './admin/pages/adminLogin';
import AdminResetPassword from './admin/pages/adminResetPassword';
import AdminForgotPassword from './admin/pages/adminForgotPassword';
import AdminDashboard from './admin/pages/adminDashboard';
import AdminQuotes from './admin/pages/adminQuotes';
import AdminQuoteView from './admin/pages/adminQuoteViews';
import AdminQuoteEdit from './admin/pages/adminQuoteEdit';
import AdminUsers from './admin/pages/adminUsers';
import AdminUserView from './admin/pages/adminUserView';

// App
import AppLayout from './app/components/appLayout';
import AppLogin from './app/pages/appLogin';
import AppRegister from './app/pages/appRegister';
import AppForgotPassword from './app/pages/appForgotPassword';
import AppResetPassword from './app/pages/appResetPassword';
import AppProducts from './app/pages/appProducts';
import AppQuotes from './app/pages/appQuotes';
import AppCart from './app/pages/appCart';
import AppCartEdit from './app/pages/appCartEdit';
import Address from './app/pages/appAddress';
import AppWishlist from './app/pages/appWishlist';
import AppProfile from './app/pages/appProfile';
import ThankYou from './app/pages/thankYou';
import adminDocuments from './admin/pages/adminDocuments';
import adminUploadDocument from './admin/pages/adminUploadDocument';
import appDocuments from './app/pages/appDocuments';

class App extends React.Component {
  render() {
    const { user } = this.props;
    const isAllowed = isEmpty(user);
    return (
      <>
        <BrowserRouter>
          <Switch>
            {/* Admin pages */}
            <AppRoute exact path="/admin" layout={PublicLayout} component={AdminLogin} />
            <AppRoute exact path="/admin/forgot-password" layout={PublicLayout} component={AdminForgotPassword} />
            <AppRoute exact path="/admin/reset-password/:token" layout={PublicLayout} component={AdminResetPassword} />
            {!isAllowed && <AppRoute exact path="/admin/dashboard" layout={AdminLayout} component={AdminDashboard} />}
            {!isAllowed && <AppRoute exact path="/admin/quotes" layout={AdminLayout} component={AdminQuotes} />}
            {!isAllowed && <AppRoute exact path="/admin/quotes/:id" layout={AdminLayout} component={AdminQuoteView} />}
            {!isAllowed && <AppRoute exact path="/admin/quotes/:quoteId/edit/:id" layout={AdminLayout} component={AdminQuoteEdit} />}
            {!isAllowed && <AppRoute exact path="/admin/users" layout={AdminLayout} component={AdminUsers} />}
            {!isAllowed && <AppRoute exact path="/admin/users/:id/edit" layout={AdminLayout} component={AdminUserView} />}
            {!isAllowed && <AppRoute exact path="/admin/documents" layout={AdminLayout} component={adminDocuments} />}
            {!isAllowed && <AppRoute exact path="/admin/document/upload" layout={AdminLayout} component={adminUploadDocument} />}
            {/* App pages */}
            <AppRoute exact path="/" layout={PublicLayout} component={AppLogin} />
            <AppRoute exact path="/register" layout={PublicLayout} component={AppRegister} />
            <AppRoute exact path="/forgot-password" layout={PublicLayout} component={AppForgotPassword} />
            <AppRoute exact path="/reset-password/:token" layout={PublicLayout} component={AppResetPassword} />
            <AppRoute exact path="/thank-you" layout={PublicLayout} component={ThankYou} />
            {!isAllowed && <AppRoute exact path="/products" layout={AppLayout} component={AppProducts} />}
            {!isAllowed && <AppRoute exact path="/cart" layout={AppLayout} component={AppCart} />}
            {!isAllowed && <AppRoute exact path="/cart/:id/edit" layout={AppLayout} component={AppCartEdit} />}
            {!isAllowed && <AppRoute exact path="/documents" layout={AppLayout} component={appDocuments} />}
            {/* {!isAllowed && <AppRoute exact path="/address/:type" layout={AppLayout} component={Address} />} */}
            {!isAllowed && <AppRoute exact path="/address" layout={AppLayout} component={Address} />}
            {!isAllowed && <AppRoute exact path="/quotes" layout={AppLayout} component={AppQuotes} />}
            {!isAllowed && <AppRoute exact path="/profile" layout={AppLayout} component={AppProfile} />}
            {!isAllowed && <AppRoute exact path="/wishlist" layout={AppLayout} component={AppWishlist} />}
            <Route path="*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(App);
