import React from 'react';

class Amount extends React.Component {
  render() {
    const { value } = this.props;
    return new Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(value);
  }
}

export default Amount;
