import React, { Component } from 'react';
import Amount from '../../components/Amount';

class DashboardCard extends Component {
  render() {
    const { title, icon, isAmount, value } = this.props;
    return (
      <div className="col-12 col-lg-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">{title}</h6>
              </div>
              <div className="col-auto">
                <span className={`h2 fe ${icon} text-muted mb-0`} />
              </div>
            </div>
            <div className="mt-3">
              <span className="h2 mb-0">{isAmount ? <Amount value={value} /> : value}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardCard;
