import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, filter } from 'lodash';
import IdleTimer from 'react-idle-timer';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom';

class AppNavigation extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  componentDidMount() {
    const { user, history } = this.props;
    if (isEmpty(user)) {
      history.push('/');
    }
  }

  doLogout = async () => {
    const { dispatch } = this.props;
    await dispatch({ type: 'USER', payload: {} });
    window.location.href = '/';
  };

  onIdle = async (e) => {
    const { dispatch } = this.props;
    await dispatch({ type: 'USER', payload: {} });
    window.location.href = '/';
  };

  render() {
    const { cart, user } = this.props;
    const cartItems = (cart && filter(cart, (item) => item.userId === user.id)) || [];
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={10800000}
        />

        {this.props.user && (
          <nav className="navbar navbar-expand-lg navbar-merck" id="topnav">
            <div className="container">
              <button className="navbar-toggler mr-auto merck-yellow" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fe fe-menu text-merck-yellow" />
              </button>

              <Link to="/products" className="navbar-brand d-lg-none mr-auto">
                <img src="/img/logo.png" alt="mprice-logo" className="navbar-brand-img" />
              </Link>

              <div className="navbar-user">
                <div className="dropdown">
                  <a href="#!" className="avatar avatar-sm dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={this.props.user.photo_url === undefined || this.props.user.photo_url === '' ? '/img/user.png' : this.props.user.photo_url} alt="user" className="avatar-img rounded-circle mb-3" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/profile" className="dropdown-item">
                      Profile
                    </Link>
                    <Link to="/" className="dropdown-item" onClick={() => this.doLogout()}>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>

              <div className="collapse navbar-collapse mr-lg-auto order-lg-first" id="navbar">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item d-none d-lg-block mr-4 mt-2">
                    <Link to="/products" className="navbar-brand mr-auto">
                      <img src="/img/logo.png" alt="mprice-logo" className="navbar-brand-img" />
                    </Link>
                  </li>

                  <li className="nav-item mr-4" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/products" className={this.props.location.pathname === '/products' ? 'nav-link active' : 'nav-link'}>
                      Products
                    </Link>
                  </li>

                  <li className="nav-item mr-4" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/quotes" className={this.props.location.pathname === '/quotes' ? 'nav-link active' : 'nav-link'}>
                      Quote Requests
                    </Link>
                  </li>

                  <li className="nav-item mr-4" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/wishlist" className={this.props.location.pathname === '/wishlist' ? 'nav-link active' : 'nav-link'}>
                      Wishlist
                    </Link>
                  </li>

                  <li className="nav-item mr-4" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/cart" className={this.props.location.pathname === '/cart' ? 'nav-link active' : 'nav-link'}>
                      Cart Items
                      {cartItems && cartItems.length > 0 && <span className="text-muted ml-2">({cartItems.length})</span>}
                    </Link>
                  </li>

                  <li className="nav-item mr-4" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/documents" className={this.props.location.pathname === '/documents' ? 'nav-link active' : 'nav-link'}>
                      Documents
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
  };
};

export default connect(mapStateToProps)(withRouter(AppNavigation));
