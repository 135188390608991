import React, { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/Spinner';
import { auth } from '../../services/authServices';
import specialRole from '../../helpers/specialRole';
import { Link, withRouter } from 'react-router-dom';
import { errors, roles } from '../constants/Constants';
import SimpleReactValidator from 'simple-react-validator';
import { trackPageView, trackEvent, trackException } from '../../helpers/analytics';

toast.configure();

class AdminLogin extends PureComponent {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Please enter a valid email',
        default: 'This field is required',
      },
    });
    this.state = {
      email: '',
      password: '',
      loading: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    const { user, history } = this.props;
    if (isEmpty(user)) {
      history.push('/admin');
    } else {
      if ((user.roles.length > 0 && user.site === 'admin' && user.roles.includes('admin')) || user.roles.includes('super-admin')) {
        history.push('/admin/dashboard');
      }
    }
    trackPageView();
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onShowPassword = () => {
    const { password, showPassword } = this.state;
    if (password) {
      this.setState({ showPassword: !showPassword });
    }
  };

  onSubmit = async (event) => {
    event.preventDefault();
    trackEvent('Admin Login', 'Submit');
    const { email, password } = this.state;
    const { dispatch, history } = this.props;
    if (email && password) {
      this.setState({ loading: true });
      const request = { email: email.toLowerCase().trim(), password: password };
      const response = await auth(request);
      try {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            let user = response.data;
            const fixedUserData = { ...response.data };
            await localStorage.setItem('token', fixedUserData.token);
            if (user.hasOwnProperty('roles')) {
              if (user.roles.includes(roles.ADMIN_USER) || user.roles.includes(roles.SUPER_ADMIN_USER)) {
                const isAdminViewRole = (item) => item.email === user.email;
                const isAdminView = specialRole && specialRole.some(isAdminViewRole);
                if (isAdminView) {
                  user.specialRole = true;
                } else {
                  user.specialRole = false;
                }
                user.site = 'admin';
                dispatch({ type: 'USER', payload: user });
                trackEvent('Sign In', 'Success');
                await window.location.reload();
                setTimeout(() => {
                  history.push('/admin/dashboard');
                }, 1500);
              } else {
                await this.setState({ loading: false });
                toast.error(errors.ACCESS_DENIED);
              }
            } else {
              await this.setState({ loading: false });
              toast.error(errors.ACCESS_DENIED);
            }
          } else {
            this.setState({ loading: false });
            trackException('Invalid email / password. Please try again.', false);
            toast.error('Invalid Email / Password. Please try again.');
          }
        }
      } catch (error) {
        this.setState({ loading: false });
        console.warn(error);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      event.preventDefault();
    }
  };

  render() {
    return (
      <div className="d-flex align-items-center vh-100 bg-merck-yellow">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
              <div className="text-center">
                <img src="/img/login.svg" alt="..." className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <div className="text-center">
                <h1 className="font-merck text-merck-pink text-center">
                  The Power Tool<b className="font-verdana text-merck-pink text-center"></b>
                </h1>
                <h2 className="font-merck text-merck-purple text-center mb-5 mt-3">Admin Console</h2>
                {process.env.REACT_APP_ENV === 'dev' && <h3 className="font-merck text-merck-pink text-center mb-5">Development</h3>}
              </div>
              <Spinner display={this.state.loading}>
                <form>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input name="email" type="email" className="form-control" placeholder="john@gmail.com" value={this.state.email} onChange={this.onInputChange} />
                    <span className="text-danger">{this.validator.message('', this.state.email, 'required|email')}</span>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label>Password</label>
                      </div>
                      <div className="col-auto">
                        <Link to="/admin/forgot-password" className="form-text small" tabIndex="-1">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <div className="input-group input-group-merge">
                      <input name="password" type={this.state.showPassword ? 'text' : 'password'} className="form-control form-control-appended" placeholder="Enter your password" value={this.state.password} onChange={this.onInputChange} />
                      <div className="input-group-append">
                        <span className="input-group-text" onClick={this.onShowPassword}>
                          <i className={this.state.showPassword ? 'fe fe-eye' : 'fe fe-eye-off'} />
                        </span>
                      </div>
                    </div>
                    <span className="text-danger">{this.validator.message('', this.state.password, 'required')}</span>
                  </div>
                  <button onClick={this.onSubmit} className="btn btn-lg btn-block btn-merck-pink" type="submit">
                    Sign in
                  </button>
                </form>
              </Spinner>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(AdminLogin));
