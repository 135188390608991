import { createStore } from 'redux';

const appReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER':
      return { ...state, user: action.payload };
    case 'EDIT_LOGGED_USER':
      return { ...state, user: { ...state.user, ...action.payload } };
    case 'CART':
      return { ...state, cart: action.payload };
    case 'DISTRIBUTORS':
      return { ...state, distributor: action.payload };
    default:
      return state;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('merckph');
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('merckph', serializedState);
  } catch (err) {}
};

const store = createStore(appReducer, loadState());
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
