/*eslint-disable*/
import Swal from 'sweetalert';

export const confirmBox = (text) =>
  Swal({
    title: `Confirm Delete`,
    text: text,
    icon: 'error',
    dangerMode: true,
    buttons: ['Cancel', 'Yes'],
    modal: false,
  });

//Regex for validations
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_PATTERN = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
export const TAX_PATTERN = /\d{3}\-\d{3}\-\d{3}\-\d{3}/;

//Error Messages
export const errors = {
  FIELD_REQUIRED: 'This field is required',
  INVALID_EMAIL: 'Please enter a valid email',
  FAILED_TO_FETCH: 'Failed to fetch the data',
  INVALID_FORMAT: 'File format not supported.',
  FOLDER_NAME_REQUIRED: 'Folder name is required',
  FAILED_TO_UPDATE: 'Failed to update files count.',
  FAILED_TO_FETCH_COUNT: 'Failed to fetch files count.',
  FAILED_TO_UPDATE_FOLDER_NAME: 'Failed to update the Folder name',
  FAILED_TO_PROCESS: 'Failed to process the request',
  ACCESS_DENIED: 'User access denied. Please contact admin.',
  INVALID_CREDENTIALS: 'Invalid Email / Password. Please try again.',
  REQUEST_FAILURE: 'An error occured while processing your request. Please try again or contact admin',
  REQUEST_FAILED: 'Oops! There was an error trying to process your request. Please try again or contact admin',
};

//Login screen constants
export const UID = 'uid';
export const USER = 'user';
export const ROLES = 'roles';

//Team screen constants
export const EMAIL = 'email';
export const PHONE = 'phone';
export const LASTNAME = 'lastName';
export const FIRSTNAME = 'firstName';
export const ADDED_SUCCESSFULLY = `{name} has been added successfully`;
export const EDITED_SUCCESSFULLY = `{name} has been edited successfully`;

//Library screen constants
export const ADD_TAG = 'Please add one tag.';
export const UPLOAD_FILE = 'Please upload file.';
export const SUCESSFULLY_UPDATED = 'Folder updated successfully';

export const capitalize = (name) => {
  if (typeof name !== 'string') return '';
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const checkExtension = (file) => {
  const isValidExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
  if (['.jpg', '.jpeg', '.png', '.gif', '.jfif'].some((e) => e === String(isValidExtension))) return false;
  return true;
};

export const addDefaultSrc = (e) => {
  e.target.src = '/img/default-user.png';
};

//User Roles
export const roles = {
  ADMIN_USER: 'admin',
  ADMIN_VIEW: 'admin-view',
  SUPER_ADMIN_USER: 'super-admin',
};
