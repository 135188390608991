import { roles } from '../admin/constants/Constants';

const specialRole = [
  {
    id: '01',
    role: roles.ADMIN_VIEW,
    email: 'shiela.vicquera@merckgroup.com',
  },
  {
    id: '02',
    role: roles.ADMIN_VIEW,
    email: 'lawrence.patricio@external.merckgroup.com',
  },
];

export default specialRole;
