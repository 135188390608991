import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Header from '../../admin/components/AdminHeader';
import { trackPageView, trackEvent } from '../../helpers/analytics';
import ContentContainer from '../../admin/components/ContentContainer';
import { getAllQuotes, getDistributors, getAllBussinessUnit } from '../../services';

const moment = require('moment');
const cartHelper = require('../../helpers/appHelper');

class AdminQuotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      bussinessUnitList: [],
      distributorList: [],
      keyword: '',
      currentPage: 1,
      loading: false,
      distributorFilter: '',
      bussinessFilter: '',
      filteredQuotesList: [],
      startDate: moment().subtract(30, 'days').endOf('day')['_d'],
      endDate: moment().endOf('day')['_d'],
      filteredQuotes: false,
    };
  }

  componentDidMount() {
    this.getQuoteList();
    this.getAllDistributors();
    this.getBussinessUnits();
    trackPageView();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { quotes, bussinessFilter, distributorFilter } = this.state;
    if (prevState.bussinessFilter !== bussinessFilter || prevState.distributorFilter !== distributorFilter) {
      this.getFilteredQuotes(quotes);
    }
  };

  getQuotesBasedOnDistributors = (quotes) => {
    const filteredDistributors = quotes.filter((item) => item.distributor.name.toLowerCase() === this.state.distributorFilter.toLowerCase());
    this.setState({ filteredQuotesByDistributor: filteredDistributors });
  };

  getFilteredQuotes = (quotes) => {
    const combinationalSearchData = [];
    const distributorFilteredData = [];
    const bussinessUnitFilteredData = [];
    quotes.map((item) => {
      item.products.filter((product) => {
        if (product.distributorName.toLowerCase() === this.state.distributorFilter.toLowerCase() && product.business_unit.toLowerCase() === this.state.bussinessFilter.toLowerCase()) {
          combinationalSearchData.push(product);
        } else if (product.distributorName.toLowerCase() === this.state.distributorFilter.toLowerCase()) {
          distributorFilteredData.push(product);
        } else if (product.business_unit.toLowerCase() === this.state.bussinessFilter.toLowerCase()) {
          bussinessUnitFilteredData.push(product);
        }
        return { combinationalSearchData, distributorFilteredData, bussinessUnitFilteredData };
      });
      return quotes;
    });
    if (this.state.bussinessFilter.length > 0 && this.state.distributorFilter.length > 0) {
      this.setState({ filteredQuotesList: combinationalSearchData });
    }
    if (this.state.distributorFilter.length > 0 && this.state.bussinessFilter === '') {
      this.setState({ filteredQuotesList: distributorFilteredData });
    }
    if (this.state.bussinessFilter.length > 0 && this.state.distributorFilter === '') {
      this.setState({ filteredQuotesList: bussinessUnitFilteredData });
    }
  };

  doLogout = async () => {
    await this.props.dispatch({ type: 'USER', payload: {} });
    await localStorage.removeItem('token');
    window.location.href = '/admin';
  };

  getQuoteList = async () => {
    this.setState({ loading: true });
    let { startDate, endDate } = this.state;
    try {
      const response = await getAllQuotes();
      startDate = moment(startDate).format('llll');
      endDate = moment(endDate).format('llll');
      if (response) {
        if (response.status === 200) {
          let quoteList = response.data.filter((item) => moment(moment(item.created_on).format('llll')).isBetween(startDate, endDate));
          map(quoteList, (quote) => {
            map(quote.products, (item) => {
              item.distributorName = quote.distributor.name;
              item.quoteId = quote.id;
              item.status = quote.status;
              item.date = moment(quote.created_on).format('DD-MM-YYYY');
            });
          });
          this.setState({ quotes: quoteList || [], loading: false });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  getAllDistributors = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await getDistributors(token);
      if (response) {
        if (response.status === 200) {
          const dbDistributors = response.data;
          this.setState({ distributorList: dbDistributors });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  getBussinessUnits = async () => {
    try {
      const response = await getAllBussinessUnit();
      if (response) {
        if (response && response.status === 200) {
          let groupsArray = response.data;
          groupsArray = groupsArray.filter((item) => item.name !== 'ALL');
          this.setState({ bussinessUnitList: groupsArray });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  onSearch = async ({ target: { value } }) => {
    const { quotes } = this.state;
    const search = value.toLowerCase().trim().replace(/ /g, '');
    this.setState({
      keyword: value,
      filteredQuotes: !value
        ? false
        : quotes.filter((item) => {
          return item.id.toLowerCase().trim().replace(/ /g, '').includes(search) || item.distributor.name.toLowerCase().trim().replace(/ /g, '').includes(search);
        }),
    });
  };

  download = async (quote) => {
    const quoteDate = quote ? moment(quote.created_on).format('LLL') : '';
    const quoteValidity = quote ? moment(quote.created_on).add(90, 'days').format('LLL') : '';
    await cartHelper.AdminDownloadQuote(quote.products, quote.customer, quote.id, quote.address, 0, quoteDate, quote.request_type, quoteValidity);
    trackEvent('Admin Quotes', 'Download', quote.id);
  };

  nextPage = () => {
    const { currentPage } = this.state;
    const page = currentPage + 1;
    this.setState({ currentPage: page });
  };

  prevPage = () => {
    const { currentPage } = this.state;
    let page = currentPage;
    if (page > 1) {
      page = page - 1;
      this.setState({ currentPage: page });
    }
  };

  selectDates = () => {
    trackEvent('Admin Dashboard', 'Select Dates');
    this.setState({
      selectDates: true,
    });
  };

  handleDateSelect = (ranges) => {
    this.setState({
      startDate: moment(ranges.selection.startDate).startOf('day')._d,
      endDate: moment(ranges.selection.endDate).endOf('day')._d,
    });
  };

  handleApply = () => {
    this.setState(
      {
        loading: true,
        selectDates: false,
      },
      async () => {
        this.getQuoteList();
      }
    );
  };

  handleCancel = () => {
    this.setState({
      selectDates: false,
    });
  };

  setFilter = (name, value) => {
    if (name === 'distributor') {
      this.setState({ distributorFilter: value });
    } else if (name === 'bussiness') {
      this.setState({ bussinessFilter: value });
    }
  };

  downloadDashboardData = (quoteData) => {
    const quotes = quoteData.filter((item) => item.status !== 'Open');
    const { startDate, endDate } = this.state;
    trackEvent('Admin Quotes', 'Download Quotes');
    let saveAs = require('file-saver');
    let xl = require('excel4node');
    let wb = new xl.Workbook();
    let tableHeaderStyle = wb.createStyle({ font: { size: 11, bold: true }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
    let tableItemStyle = wb.createStyle({ font: { size: 11 } });
    let moneyItemStyle = wb.createStyle({ font: { size: 11 }, numberFormat: '₱#,##0.00; (₱#,##0.00); -' });
    let discountStyle = wb.createStyle({ font: { size: 11 }, color: '#ff0000', numberFormat: '0.00%; (0.00%); -' });
    if (quotes.length > 0) {
      let ws = wb.addWorksheet('Sheet1');
      ws.column(1).setWidth(13);
      ws.column(2).setWidth(13);
      ws.column(3).setWidth(30);
      ws.column(4).setWidth(28);
      ws.column(5).setWidth(16);
      ws.column(6).setWidth(25);
      ws.column(7).setWidth(15);
      ws.column(8).setWidth(20);
      ws.column(9).setWidth(20);
      ws.column(10).setWidth(20);
      ws.column(11).setWidth(40);
      ws.column(12).setWidth(24);
      //Headers
      ws.cell(1, 1).string(`Date`).style(tableHeaderStyle);
      ws.cell(1, 2).string(`Quotation #`).style(tableHeaderStyle);
      ws.cell(1, 3).string(`Distributor`).style(tableHeaderStyle);
      ws.cell(1, 4).string('Business Unit').style(tableHeaderStyle);
      ws.cell(1, 5).string('Layout').style(tableHeaderStyle);
      ws.cell(1, 6).string('Material Description').style(tableHeaderStyle);
      ws.cell(1, 7).string(`Quantity`).style(tableHeaderStyle);
      ws.cell(1, 8).string('List Price').style(tableHeaderStyle);
      ws.cell(1, 9).string('Approved Price').style(tableHeaderStyle);
      ws.cell(1, 10).string('Discount').style(tableHeaderStyle);
      ws.cell(1, 11).string('Remarks').style(tableHeaderStyle);
      ws.cell(1, 12).string('Total').style(tableHeaderStyle);
      let row = 1;
      quotes.forEach((product) => {
        row++;
        const approvedPrice = Number(product?.approved_price || product?.asked_price || product?.discounted_price || product?.price || 0)
        // const approvedPrice = Number(price) > 0 ? Number(price) : product.discounted_price > 0 ? product.discounted_price : product.price;
        const discount = Number((product?.price - approvedPrice) / product?.price) || 0

        const total = approvedPrice * product.quantity;
        ws.cell(row, 1).string(product.date).style(tableItemStyle);
        ws.cell(row, 2).string(product.quoteId).style(tableItemStyle);
        ws.cell(row, 3).string(product.distributorName).style(tableItemStyle);
        ws.cell(row, 4).string(product?.business_unit).style(tableItemStyle);
        ws.cell(row, 5).string(product.id).style(tableItemStyle);
        ws.cell(row, 6).string(product.name).style(tableItemStyle);
        ws.cell(row, 7).number(product?.quantity||0).style(tableItemStyle);
        ws.cell(row, 8).number(product?.price||0).style(moneyItemStyle);
        ws.cell(row, 9).number(approvedPrice).style(moneyItemStyle);
        ws.cell(row, 10).number(discount||0).style(discountStyle);
        ws.cell(row, 11).string(product.comment).style(tableItemStyle);
        ws.cell(row, 12).number(total||0).style(moneyItemStyle);
      });
    }
    wb.writeToBuffer().then(function (buffer) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Quotations-${moment(startDate).format('DD-MMM')}-${moment(endDate).format('DD-MMM')}.xlsx`);
    });
  };

  render() {
    const { quotes, currentPage, filteredQuotes, keyword, bussinessFilter, filteredQuotesList, distributorFilter, distributorList, bussinessUnitList } = this.state;
    const lastindex = currentPage * 20;
    const firstindex = lastindex - 20;
    const cartQuotes = filteredQuotes ? filteredQuotes && filteredQuotes.slice(firstindex, lastindex) : quotes && quotes.slice(firstindex, lastindex);
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: 'selection',
    };
    const isDownload = bussinessFilter.length > 0 || distributorFilter.length > 0 ? !filteredQuotesList.length || filteredQuotesList.length === 0 : !quotes.length || quotes.length === 0;
    const defaultQuotesProducts = [];
    quotes.map((item) => {
      item.products.filter((product) => {
        if (distributorFilter === '' && bussinessFilter === '') {
          defaultQuotesProducts.push(product);
        }
        return defaultQuotesProducts;
      });
      return quotes;
    });
    return (
      <>
        <Header title="Quotations">
          <>
            <div className="btn l-1">
              <Dropdown>
                <Dropdown.Toggle variant="white">Distributor{distributorFilter && <span>: {distributorFilter}</span>}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      this.setFilter('distributor', '');
                    }}
                  >
                    All
                  </Dropdown.Item>
                  {distributorList &&
                    distributorList.map((distributor, i) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={i}
                            onClick={() => {
                              this.setFilter('distributor', distributor.name);
                            }}
                          >
                            {distributor.name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="btn ml-1">
              <Dropdown>
                <Dropdown.Toggle variant="white">Business Unit{bussinessFilter && <span>: {bussinessFilter}</span>}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      this.setFilter('bussiness', '');
                    }}
                  >
                    All
                  </Dropdown.Item>
                  {bussinessUnitList &&
                    bussinessUnitList.map((unit, i) => {
                      return (
                        <>
                          <Dropdown.Item
                            key={i}
                            onClick={() => {
                              this.setFilter('bussiness', unit.name);
                            }}
                          >
                            {unit.name}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        </Header>
        <Spinner display={this.state.loading}>
          <div className="container mt-5">
            <div className="row">
              <div className="col">
                <button className="btn btn-white mb-3" onClick={this.selectDates}>{`${moment(this.state.startDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</button>
              </div>
              <div className={`col-auto`}>
                <button
                  disabled={isDownload}
                  className={isDownload ? 'btn btn-secondary mb-3' : 'btn btn-info mb-3'}
                  type="button"
                  onClick={() => {
                    this.downloadDashboardData(filteredQuotesList.length > 0 ? filteredQuotesList : defaultQuotesProducts);
                  }}
                >
                  <i className="fe fe-download mr-1" />
                  Download
                </button>
              </div>
            </div>

            {this.state.selectDates && (
              <div className="container" style={{ position: 'absolute', zIndex: 9 }}>
                <DateRangePicker ranges={[selectionRange]} onChange={this.handleDateSelect} showDateDisplay={true} hasCustomRendering={true} inputRanges={[]} />
                <div className="d-flex" style={{ position: 'absolute', bottom: '3%', left: '5%' }}>
                  <button className="btn btn-sm btn-primary m-2" onClick={this.handleApply}>
                    Apply
                  </button>
                  <button className="btn btn-sm btn-light m-2" onClick={this.handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {quotes.length > 0 ? (
              <>
                <div className="card">
                  <div className="card-header">
                    <form>
                      <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                        <input
                          value={keyword}
                          className="form-control search"
                          type="search"
                          placeholder="Search by Quotation ID / Company Name"
                          onChange={this.onSearch}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                          }}
                        />
                      </div>
                    </form>
                  </div>
                  <ContentContainer size={cartQuotes && cartQuotes?.length} isKeywordAndNoDataFound={!cartQuotes.length && keyword}>
                    <div className="table-responsive">
                      <table className="table table-sm table-nowrap card-table">
                        <thead>
                          <tr>
                            <th>Quote Request #</th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Distributor</th>
                            <th className="text-right">Total</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {cartQuotes.map((quote, index) => (
                            <tr key={index}>
                              <td>{quote.id}</td>
                              <td>{moment(quote.created_on).format('ll')}</td>
                              <td>{quote.customer.name}</td>
                              <td>{quote.distributor.name}</td>
                              <td className="text-right">
                                <Amount value={quote.total} />
                              </td>
                              <td className="text-center">
                                <h4>
                                  <span className={quote.status === 'Open-Closed' ? 'badge badge-soft-success ml-2' : quote.status === 'Open' ? 'badge badge-soft-warning ml-2' : 'badge badge-soft-info ml-2'}>{quote.status === 'Open' ? 'Pending' : quote.status === 'Open-Closed' ? 'Reviewed' : 'Closed'}</span>
                                </h4>
                              </td>
                              <td className="text-right">
                                <button className="btn btn-sm btn-light mx-1" onClick={() => this.download(quote)}>
                                  <i className="fe fe-download"></i>
                                </button>
                                <Link to={`/admin/quotes/${quote.id}`}>
                                  <button className="btn btn-sm btn-success mx-1">
                                    <i className="fe fe-eye"></i>
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </ContentContainer>
                </div>
                {/* Pagination */}
                <ul className="pagination pagination-sm justify-content-center">
                  {cartQuotes.length > 0 && (
                    <li className={this.state.currentPage > 1 || cartQuotes.length === 0 ? 'page-item' : 'page-item disabled'}>
                      <div className="page-link" href="#!" onClick={this.prevPage} style={{ cursor: 'pointer' }}>
                        Previous
                      </div>
                    </li>
                  )}
                  {cartQuotes.length > 0 && (
                    <li className="page-item disabled">
                      <div className="page-link" href="#!">
                        Page {this.state.currentPage}
                      </div>
                    </li>
                  )}
                  {cartQuotes.length > 0 && (
                    <li className={cartQuotes.length < 20 ? 'page-item disabled' : 'page-item'}>
                      <div className="page-link" href="#!" onClick={this.nextPage} style={{ cursor: 'pointer' }}>
                        Next
                      </div>
                    </li>
                  )}
                </ul>
              </>
            ) : (
              <div className="card">
                <div className="m-5 text-center">
                  <div className="row">
                    <div className="col-lg-4 offset-lg-4 text-center">
                      <img src="/img/empty_cart.png" className="img-fluid" alt="no-data-found" />
                      <p className="mt-3">Looks like you don't have any Quotation!</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Spinner>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminQuotes);
